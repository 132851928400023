import React, { useContext, useState } from "react";
import Navbar from "../components/Navbar";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../UserContext";
import Footer from "../components/Footer";

export default function Login() {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const { setUserInfo } = useContext(UserContext);

  async function login(ev) {
    ev.preventDefault();

    try {
      const response = await axios.post(
        "https://reaktech.onrender.com/login",
        { name: name, password: password },
        { withCredentials: true }
      );

      if (response.status === 200) {
        const userInfo = response.data;
        setUserInfo(userInfo);
        setRedirect(true);
      } else {
        console.log(response);
      }
    } catch (err) {
      console.error("Login delayed!", err);
    }
  }

  if (redirect) {
    return <Navigate to={"/"} />;
  }

  return (
    <>
      <Navbar />
      <section className="section-randl">
        <div className="container">
          <div className="randl">
            <div className="randl-text-box">
              <h2 className="heading-primary" style={{ color: "#042c71" }}>
                Login
              </h2>
              <form action="post" className="randl-form" onSubmit={login}>
                <div>
                  <label htmlFor="full-name">Full Name</label>
                  <input
                    type="text"
                    id="full-name"
                    placeholder="Alp Eren"
                    value={name}
                    onChange={(ev) => setName(ev.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(ev) => setPassword(ev.target.value)}
                  />
                </div>
                <button className="btn btn--form">Let me In!</button>
              </form>
            </div>
            <div
              className="login-img-box"
              role="img"
              aria-label="Register image"
            ></div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
