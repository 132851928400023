import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Card from "../components/Card";
import AvatarMan from "../imgs/avatar_man.png";
import AvatarWoman from "../imgs/avatar_woman.png";

export default function AllTeam() {
  return (
    <>
      <Navbar />
      <div className="all-team">
        <span
          className="container subheading padding-top-sm"
          style={{ color: "#02122d", fontSize: "2.4rem" }}
        >
          Our Team
        </span>
        <div
          className="container grid grid--3-cols margin-bottom-md"
          style={{ rowGap: "4.8rem" }}
        >
          <Card
            img={AvatarMan}
            name={"Alp Eren"}
            job={"Software Developer"}
            skills={"React, JS, CSS and more"}
            link={"https://alpsa.tech"}
          />
          <Card
            img={AvatarWoman}
            name={"Sude Keskin"}
            job={"Graphic Designer"}
            skills={"Autocad, Archicad, Canva and more"}
            link={"https://bionluk.com/sudekeskinn_"}
          />
          <Card
            img={AvatarWoman}
            name={"Burak"}
            job={"Graphic Designer"}
            skills={"Adobe, Photoshop and more"}
            link={"https://bionluk.com/burak_design"}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}
