import React from "react";
import { Link } from "react-router-dom";

export default function Card(props) {
  return (
    <div className="member">
      <img
        src={props.img}
        alt="Avatar for men"
        style={{ backGroundColor: "#8296b8" }}
      />
      <hr />
      <div className="member-content">
        <div className="member-name">
          <span className="name">{props.name}</span>
        </div>
        <ul className="member-attributes">
          <li className="member-attribute">
            <ion-icon name="checkmark-circle-outline"></ion-icon>
            <span>{props.job}</span>
          </li>
          <li className="member-attribute">
            <ion-icon name="construct-outline"></ion-icon>
            <span>{props.skills} &darr;</span>
          </li>
          <li>
            <Link to={props.link} className="btn btn--team">
              Go Website
              <ion-icon name="open-outline"></ion-icon>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
