import React from "react";

export default function Feature() {
  return (
    <>
      <div className="container grid">
        <h2 className="heading-tertiary" style={{ marginTop: "4.8rem" }}>
          But why us? Because:
        </h2>
      </div>
      <div className="container grid grid--3-cols">
        <div className="feature">
          <ion-icon class="feature-icon" name="alarm-outline"></ion-icon>

          <p className="feature-title">Time saver</p>
          <p className="feature-text">
            Time is everything, let's save the time you spend researching.
          </p>
        </div>
        <div className="feature">
          <ion-icon class="feature-icon" name="happy-outline"></ion-icon>
          <p className="feature-title">Happy clients</p>
          <p className="feature-text">
            Customer satisfaction is very important to us. Our job is to help
            you.
          </p>
        </div>
        <div className="feature last-element">
          <ion-icon class="feature-icon" name="mic-outline"></ion-icon>
          <p className="feature-title">Audio assistance</p>
          <p className="feature-text">
            Even if it's not 24/7 yet 😔. You can get voice support via Discord.
          </p>
        </div>
      </div>
    </>
  );
}
