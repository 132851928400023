import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../imgs/logo.png";
import axios from "axios";
import { UserContext } from "../UserContext";

export default function Navbar(props) {
  const { setUserInfo } = useContext(UserContext);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavClick = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {}, [props.name]);

  function logout() {
    axios
      .post(
        "https://reaktech.onrender.com/logout",
        {},
        { withCredentials: true }
      )
      .then((res) => {
        if (res.status === 200) {
          setUserInfo(null);
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <header className={`header ${isNavOpen ? "nav-open" : ""}`}>
      <Link to={"/"}>
        <img className="logo" src={Logo} alt="ReakTech logo" />
      </Link>
      <nav className="main-nav">
        <ul className="main-nav-list">
          <li>
            <a href="mailto:contact@reaktech.com" className="main-nav-link">
              Contact
            </a>
          </li>
          <li>
            <Link to={"/team"} className="main-nav-link">
              Team
            </Link>
          </li>

          {props.name && (
            <>
              <li>
                <Link
                  to="/logout"
                  className="main-nav-link nav-warning"
                  onClick={logout}
                >
                  Logout
                </Link>
              </li>
            </>
          )}
          {!props.name && (
            <>
              <li>
                <Link to={"/login"} className="main-nav-link">
                  Login
                </Link>
              </li>
              <li>
                <Link to={"/register"} className="main-nav-link nav-warning">
                  Let's Start!
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
      <button
        className={`btn-mobile-nav ${isNavOpen ? "nav-open" : ""}`}
        onClick={handleNavClick}
      >
        <ion-icon class="icon-mobile-nav" name="menu-outline"></ion-icon>
        <ion-icon class="icon-mobile-nav" name="close-outline"></ion-icon>
      </button>
    </header>
  );
}
