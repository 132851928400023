import { Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Register from "./pages/Register";
import { UserContextProvider } from "./UserContext";
import Login from "./pages/Login";
import AllTeam from "./pages/AllTeam";
import "./css/queries.css";
import "./css/style.css";

function App() {
  return (
    <UserContextProvider>
      <Routes>
        <Route path={"/"} element={<Main />} />
        <Route path={"/register"} element={<Register />} />
        <Route path={"/login"} element={<Login />} />
        <Route path={"/team"} element={<AllTeam />} />
      </Routes>
    </UserContextProvider>
  );
}

export default App;
