import React, { useContext } from "react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Roadmap from "../components/Roadmap";
import { UserContext } from "../UserContext";
import Team from "../components/Team";
import Feature from "../components/Feature";

export default function Main() {
  const { userInfo } = useContext(UserContext);
  const name = userInfo?.name;
  return (
    <div>
      <Navbar name={name} />
      <Header />
      <Roadmap />
      <Team />
      <Feature />
      <Footer />
    </div>
  );
}
