import React from "react";
import { Link } from "react-router-dom";
import Card from "./Card";
import AvatarMan from "../imgs/avatar_man.png";
import AvatarWoman from "../imgs/avatar_woman.png";

export default function Team() {
  return (
    <section className="section-team">
      <div className="container">
        <span
          className="subheading padding-top-sm"
          style={{ color: "#02122d" }}
        >
          Our Team
        </span>
        <h2 className="heading-secondary center-text">
          ReakTech has lovely team
        </h2>
      </div>
      <div className="container grid grid--3-cols margin-bottom-md">
        <Card
          img={AvatarMan}
          name={"Alp Sagdic"}
          job={"Software Developer"}
          skills={"React, JS, CSS and more"}
          link={"https://alpsa.tech"}
        />
        <Card
          img={AvatarWoman}
          name={"Sude Keskin"}
          job={"Graphic Designer"}
          skills={"Autocad, Canva and more"}
          link={"https://bionluk.com/sudekeskinn_"}
        />
        <Card
          img={AvatarWoman}
          name={"Tugba"}
          job={"Graphic Designer"}
          skills={"Adobe, Photoshop and more"}
          link={"https://bionluk.com/burak_design"}
        />
      </div>
      <div className="all-members">
        <Link to={"/team"}>See others &rarr;</Link>
      </div>
    </section>
  );
}
